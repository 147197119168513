
// 机器人名称格式化
const robotNameFormat=function(type,sub_type) {
    if(type=='hupan'){
        var key=type+'_'+sub_type
    }else{
        var key=type
    }
    let data = {
        "buy_sell": "0",
        "iceberg": "1",
        "banzhuan": "2",
        "wangge": "3",
        "grid": "4",// 网格-旧
        // "wave": "4",
        "shua": "5",
        "budan_new": "6",
        "hupan_buy": "7",
        "hupan_sell": "8",
        "push": "9",
        "hugevol": "10",
        "draw": "11",
        "real_genpan": "12",
        "kongpan": "13",
        "real_genpan_quick": "14",
        "shua_dex": "15",
        "real_genpan_dex": "16",
        "buy_sell_dex": "17",
        "frontrun_dex": "18",
        "snipe_dex": "19",
        "banzhuan_dex": "20",
        "shua_nft":'21',
        "buy_sell_nft":'22',
    }
    return data[key]
}
export default robotNameFormat