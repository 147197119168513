<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[2]}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<!-- 无机器人时 切换按钮 -->
					<div class="switch" v-show="!robotBoxList.length">
						<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="getList">{{$t('robot.showRunning')}}</el-checkbox>
					</div>

					<template v-if="robotBoxList.length">
						<div class="robot_list">
							<div class="head">
								<router-link tag="div" class="g_btn btn" to="/addrobot">{{$t('robot.addrobot')}}</router-link>
								<!-- <router-link tag="div" class="g_btn btn" to="/robotlog">{{$t('robot.log')}}</router-link> -->
								<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="getList">{{$t('robot.showRunning')}}</el-checkbox>
							</div>
							<div class="list">
								<div class="boxItem" v-for="(list,listIndex) in robotBoxList" :key="listIndex">
								<!-- <div class="boxItem"> -->
							<!--  -->
							<!-- <el-collapse v-model="activeNames" @change="handleChange">
								<el-collapse-item :name="listIndex" v-for="(list,listIndex) in robotBoxList" :key="listIndex"> -->
								<!--  -->
									<div class="exchangeTitle" slot="title">
										<div class="left">
											<div class="li">
												<div class="title">{{$t('common.exchange')}}：</div>
												<div class="text">{{list.web_name}}</div>
											</div>
											<div class="li" v-if="list.web!='opensea'">
												<div class="title">{{$t('common.pair')}}：</div>
												<div class="text">{{list.currency.replace('_',' / ')}}</div>
											</div>
											<div class="li" v-else>
												<div class="title">{{$t('common.opensea')}}：</div>
												<div class="text">{{list.currency.replace('_',' / ')}}</div>
											</div>
										</div>
										<div class="right btn g_btn" @click="jumpOpen(list)">
											{{isSmall=='small'?$t('common.into'):$t('common.handicap')}}
										</div>
									</div>
									<div class="itemList">
										<div class="item" v-for="(item,index) in list.robot_list" :key="listIndex+'-'+index" :class="{active: activeIndex == listIndex+'-'+index}" @click="activeIndex = listIndex+'-'+index">
											<div class="caption">{{item.typeIndex?$t('robot.list['+item.typeIndex+'].name'):''}}<i class="iconfont icon-tips" @click="openTip(item.typeIndex)"></i></div>
											<div class="bd">

												<div class="li">
													<div class="title">{{$t('robot.parameter')}}</div>
													<div class="text">{{paramFormat(item)}}</div>
												</div>
												<div class="li">
													<div class="title">{{$t('robot.current')}}</div>
													<div class="text">
														<span v-if="item.state==1" class="green">{{$t('robot.running')}}</span>
														<span v-else class="red">{{$t('robot.stopped')}}</span>
													</div>
												</div>
												<div class="li">
													<div class="title">{{$t('common.operation')}}</div>
													<div class="text">
														<span class="btn green" @click="handleEdit(item,listIndex,index)">{{$t('common.edit')}}</span>
														<span v-if="item.state==1" class="btn red" @click="handleStop(item,listIndex,index)">{{$t('common.stop')}}</span>
														<span v-else class="btn green" @click="handleStart(item,listIndex,index)">{{$t('common.run')}}</span>
														<span class="btn red" @click="handleDelete(item,listIndex,index)">{{$t('common.del')}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								<!--  -->
								<!-- </el-collapse-item>
							</el-collapse> -->
							<!--  -->
								</div>
							</div>
						</div>
					</template>
					<el-empty v-else :image="emptyImg" :image-size="120" :description="isRun==0?$t('robot.robotEmpty'):$t('robot.robotEmpty2')">
						<router-link tag="div" class="g_btn btn" to="/addrobot">{{$t('robot.addrobot')}}</router-link>
					</el-empty>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<!-- 无机器人时 切换按钮 -->
				<div class="switch">
					<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="getList">{{$t('robot.showRunning')}}</el-checkbox>
				</div>

				<template v-if="robotBoxList.length">
					<div class="robot_list_phone">
						<div class="list">
							<div class="boxItem" v-for="(list,listIndex) in robotBoxList" :key="listIndex">
								<div class="exchangeTitle" slot="title">
									<div class="left">
										<div class="li">
											<div class="title">{{$t('common.exchange')}}：</div>
											<div class="text">{{list.web_name}}</div>
										</div>
										<div class="li" v-if="list.web!='opensea'">
											<div class="title">{{$t('common.pair')}}：</div>
											<div class="text">{{list.currency.replace('_',' / ')}}</div>
										</div>
										<div class="li" v-else>
											<div class="title">{{$t('common.opensea')}}：</div>
											<div class="text">{{list.currency}}</div>
										</div>
									</div>
									<div class="right btn g_btn" @click="jumpOpen(list)">
										{{isSmall=='small'?$t('common.into'):$t('common.handicap')}}
									</div>
								</div>

								<div class="itemList">
									<div class="item" v-for="(item,index) in list.robot_list" :key="listIndex+'-'+index" :class="{active: activeIndex == listIndex+'-'+index}" @click="activeIndex = listIndex+'-'+index">
										<div class="caption">{{item.typeIndex?$t('robot.list['+item.typeIndex+'].name'):''}}<i class="iconfont icon-tips" @click="openTip(item.typeIndex)"></i></div>
										<div class="bd">

											<div class="li">
												<div class="title">{{$t('robot.parameter')}}</div>
												<div class="text">{{paramFormat(item)}}</div>
											</div>
											<div class="li">
												<div class="title">{{$t('robot.current')}}</div>
												<div class="text">
													<span v-if="item.state==1" class="green">{{$t('robot.running')}}</span>
													<span v-else class="red">{{$t('robot.stopped')}}</span>
												</div>
											</div>
											<div class="li">
												<div class="operation">
													<span v-if="item.state==1" class="g_btn btn red" @click="handleStop(item,listIndex,index)">{{$t('common.stop')}}</span>
													<span v-else class="g_btn btn green" @click="handleStart(item,listIndex,index)">{{$t('common.run')}}</span>
													<span class="g_btn btn green" @click="handleEdit(item,listIndex,index)">{{$t('common.edit')}}</span>
													<span class="g_btn btn red" @click="handleDelete(item,listIndex,index)">{{$t('common.del')}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</template>
				<el-empty v-else :image="emptyImg" :image-size="120" :description="isRun==0?$t('robot.robotEmpty'):$t('robot.robotEmpty2')">
					<router-link tag="div" class="g_btn btn" to="/addrobot">{{$t('robot.addrobot')}}</router-link>
				</el-empty>
			</div>
		</template>

		<!-- 编辑弹窗 -->
		<!-- @close="activeIndex = null" -->
		<el-dialog :title="typeIndex?$t('common.editTitle')+$t('robot.list['+typeIndex+'].name'):''" :visible.sync="showEdit" :show-close="false" center :before-close="beforeClose">
			
			<robotForm
				ref="robotForm"
				:titleIndex="titleIndex"
				@handleCancel="handleCancel"
				@handleConfirm="handleConfirm(arguments)"
				@openPop="openPop"
				:yenUnit1="yenUnit1"
				:yenUnit2="yenUnit2"
				:isEdit="true"
				:state="selectItmeState"
			></robotForm>

		</el-dialog>
		<!-- 说明弹窗 -->
		<el-dialog class="w900" :title="typeIndex?$t('robot.list['+typeIndex+'].name')+$t('common.explain'):''" center :visible.sync="showTips" :show-close="false" @close="activeIndex = null">
			<div class="tips_form" v-html="typeIndex?$t('robot.list['+typeIndex+'].html'):''"></div>
		</el-dialog>

		<!-- 机器人关闭倒计时 -->
		<template v-if="closingRobotList.length>0">
			<div class="notification" :class="{active:closingRobot[item]>0}" v-for="(item,index) in closingRobotList" :key="index" :style="{top:index==0?70+'px':70+index*90+'px'}">
				<div class="notification__group">
					<h2 class="notification__title"> {{$t('robot.list['+item+'].name')}} </h2>
					<div class="notification__content">
						<p>
							{{$t('tip.closing',{time:closingRobot[item]})}}
						</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>

		<!-- 分拨资金弹窗 -->
		<subTransfer 
		ref="subTransfer"
		:address_list="address_list"
		:id_list="id_list"
		:chain="chain"
		:token="token"
		:approve_token="approve_token"
		:web="transferWeb"
		:exchange="transferExchange"
		></subTransfer>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'index.styl';
</style>

<script>
	import { Loading } from 'element-ui';
			
	import ExchangeDialog from '@/components/exchange.vue';
	import robotForm from '@/components/robotForm.vue';
	import subTransfer from '@/components/subTransfer/subTransfer.vue';
	
	import { mapGetters } from 'vuex';
	import typeFormat from '@/utils/methApi/typeFormat';
	import robotNameFormat from '@/utils/methApi/robotNameFormat';
	import timeFormat from '@/utils/methApi/timeFormat';
	
	// api
	import { getRobotList,setRobot,delRobot } from '@/api/robot';
	import { dexWalletsApprove} from '@/api/markets';
	export default ({
		name: 'account',
		components: {
			ExchangeDialog,
			robotForm,
			subTransfer,
		},
		data() {
			return {
				showTips: false,
				showEdit: false,
				emptyImg: require('@/assets/images/loading.png'),
				activeIndex: null,
				typeIndex: null,
				isRun:'1',//仅显示运行中
				selectItmeState:0,//选中项机器人对应状态
				closingRobot:{},//存放正在关闭倒数的机器人，及倒计时
				closingRobotList:[],//存放正在关闭倒数的机器人
				
				// 此处数据仅用于测试,请自行设置数据名称
				robotBoxList:[],
				// 弹窗1
				exchange:'',
				yenUnit1:'',
				yenUnit2:'',
				// 弹窗2
				param:'',
				// 交易所选择
				popExchange: false,
				web: null,
				// 子组件的交易所
				isSonPop:false,
				web2: null,
				sonFormNum:0,
				// 折叠面板
				// activeNames:1,
				
				// 资金划分
				address_list:[],
				id_list:[],
				chain:'',
				token:'',
				approve_token:'',
				transferWeb:'',
				transferExchange:'',
			}
		},
		computed:{
			...mapGetters(['account','isSmall','isMobile']),
			titleIndex(){
				return Number(this.typeIndex)+1
			},
		},
		created(){
			this.getList();
		},
		methods: {
			// 折叠面板
			// handleChange(){
			// 	console.log(1)
			// },
			// 跳转手动操盘 进入盘口
			jumpOpen(list){
				// console.log(list)
				var arr=list.currency.split('_')
				// 缓存盘口信息
				this.$router.push({
					path:'/stocks',
					query:{
						exchange: list.web_name,
						web: list.web,
						yenUnit1: arr[0],
						yenUnit2: arr[1],
						web_id: list.web_id,
						web_type: list.web_type,
					}
				})
			},
			// 获取机器人列表
			getList(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				getRobotList({
					account:this.account,
					state:this.isRun,
				}).then(res=>{
					// console.log(res)
					if(!!res){
						res.forEach(li=>{
							if(!!li.robot_list){
								li.robot_list.forEach(item=>{
									item.typeIndex=robotNameFormat(item.type,item.sub_type)
								})
							}
						})
					}
					this.robotBoxList=res
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 参数设置字段格式化
			paramFormat(item){
				// console.log(item)
				var dataArr=item.param?.split(' ');
				if(item.type=='buy_sell'){
                	// 高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+(dataArr[4]==1? ', '+this.$t('robot.opponents'):'')+', '+this.$t('robot.maximum')+dataArr[5]+', '+this.$t('robot.minimum')+dataArr[6]+(dataArr[7]==1?', '+this.$t('robot.working')+this.$t('robot.chuck'):'')
				}else if(item.type=='iceberg'){
                	// 分批卖出机器人
					return this.$t('robot.floorPrice')+dataArr[0]+', '+this.$t('robot.interval2')+dataArr[1]+this.$t('robot.second')+', '+this.$t('robot.quantityPerTime')+dataArr[2]+', '+this.$t('robot.frequency')+dataArr[3]
				}else if(item.type=='banzhuan'){
                	// 搬砖机器人
					return this.$t('robot.targetExchange')+dataArr[0]+', '+this.$t('robot.currentCharge')+dataArr[1]+'%, '+this.$t('robot.targetCharge')+dataArr[2]+'%, '+this.$t('robot.priceDecimal')+dataArr[3]+', '+this.$t('robot.quantityDecimal')+dataArr[4]
				}else if(item.type=='wangge'){
                	// 马丁策略量化机器人
					// return this.$t('robot.openingAmount')+dataArr[0]+this.yenUnit2+', '+this.$t('robot.repTimes')+dataArr[1]+this.$t('robot.ci')+', '+this.$t('robot.repDifferences')+dataArr[2]+'%, '+this.$t('robot.interferenceRange')+dataArr[3]+'%'
					return this.$t('robot.openingAmount')+dataArr[0]+item.currency.split('_')[1]+', '+this.$t('robot.repTimes')+dataArr[1]+this.$t('robot.ci')+', '+this.$t('robot.interferenceRange')+dataArr[2]+'%, '+this.$t('robot.stopBackRatio')+dataArr[3]+'%, '+this.$t('robot.repDifferences')+dataArr[4]+'%, '+this.$t('robot.addBackRatio')+dataArr[5]+'%, '+(dataArr[6]==1?this.$t('robot.firstDouble')+', ':'')+this.$t('robot.addMultiple')+dataArr[7]+this.$t('robot.times')
				}
				// else if(item.type=='grid'){
                // 	// 网格策略量化机器人-旧
				// 	return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.grids')+dataArr[2]+', '+this.$t('robot.perGear')+dataArr[3]+', '+this.$t('robot.maintain')+dataArr[4]+', '+this.$t('robot.stopPrice')+dataArr[5]
				// }
				else if(item.type=='grid'){
				// else if(item.type=='wave'){
                	// 网格策略量化机器人
					return this.$t('robot.firstAmount')+dataArr[0]+item.currency.split('_')[1]+', '+
					this.$t('robot.grids')+dataArr[1]+', '+
					this.$t('robot.stopRatio')+dataArr[2]+'%, '+
					this.$t('robot.addInterval')+dataArr[3]+'%, '+
					this.$t('robot.addIntervalIncrease')+dataArr[4]+this.$t('robot.times')+', '+
					this.$t('robot.addAmountIncrease')+dataArr[5]+this.$t('robot.times')
				}


				else if(item.type=='shua'){
                	// 成交量对敲机器人
					return this.$t('robot.counterQuantity')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.perMinute')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.ci')+', '+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='budan_new'){
                	// 盘口管理机器人
					return this.$t('robot.orderPerNum')+dataArr[0]+', '+this.$t('robot.sellPerNum')+dataArr[1]+', '+this.$t('robot.difference')+dataArr[2]+'%, '+this.$t('robot.perDifference')+dataArr[3]+'%, '+this.$t('robot.priceDecimal')+dataArr[4]+', '+this.$t('robot.maintenance')+dataArr[5]
				}else if(item.type=='hupan'){
                	// 护盘机器人 买盘、卖盘
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.protective')+dataArr[2]+', '+this.$t('robot.gear')+dataArr[3]+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='push'){
                	// 趋势推动机器人
					// return this.$t('robot.plan')+dataArr[0]+', '+this.$t('robot.intervalRange')+dataArr[1]+'~'+dataArr[2]+this.$t('robot.random')+', '+this.$t('robot.perInterval')+dataArr[3]+', '+this.$t('robot.startPrice')+dataArr[4]+', '+this.$t('robot.targetPrice')+dataArr[5]
					return (item.sub_type=='bids'?this.$t('robot.buyBtn'):this.$t('robot.sellBtn'))+', '+
					this.$t('robot.plan')+dataArr[0]+' '+
					(item.sub_type=='bids'?item.currency.split('_')[1]:item.currency.split('_')[0])+', '+
					this.$t('robot.endTime')+timeFormat(Number(dataArr[1]),'dateTime')+', '+this.$t('robot.startPrice')+dataArr[2]+', '+this.$t('robot.targetPrice')+dataArr[3]+', '+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='hugevol'){
                	// 成交放量机器人
					return this.$t('robot.tradingVolume')+dataArr[0]+'/'+this.$t('robot.minute')+', '+
					this.$t('robot.workingHours')+dataArr[1]+this.$t('robot.minutes')+', '+
					this.$t('robot.splitBudget')+dataArr[2]+item.currency.split('_')[1]+', '+
					this.$t('robot.priceDecimal')+dataArr[3]
					// return this.$t('robot.tradingVolume')+dataArr[0]+', '+this.$t('robot.workingHours')+dataArr[1]+', '+this.$t('robot.splitBudget')+dataArr[2]+this.$t('robot.priceDecimal')+dataArr[3]
				}
				
				else if(item.type=='draw'){
                	// 画线机器人
					return this.$t('robot.upperLimit')+dataArr[0]+', '+this.$t('robot.lowerLimit')+dataArr[1]+', '+this.$t('robot.difference')+dataArr[2]+'%, '+this.$t('robot.perDifference')+dataArr[3]+'%, '+this.$t('robot.buy')+dataArr[4]+', '+this.$t('robot.offers')+dataArr[5]+', '+this.$t('robot.singleMax')+dataArr[6]+', '+this.$t('robot.priceDecimal')+dataArr[7]+', '+this.$t('robot.swiping')+dataArr[8]+this.$t('robot.individual')
				}else if(item.type=='real_genpan'){
                	// 跟盘机器人
					return (item.sub_type=='rebalance'?this.$t('robot.rebalance'):this.$t('robot.standard'))+', '+this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.difference')+dataArr[1]+'%, '+this.$t('robot.perDifference')+dataArr[2]+'%, '+this.$t('robot.buy')+dataArr[3]+', '+this.$t('robot.offers')+dataArr[4]+', '+this.$t('robot.singleMax')+dataArr[5]+', '+this.$t('robot.priceDecimal')+dataArr[6]+', '+this.$t('robot.swiping')+dataArr[7]+this.$t('robot.individual')
				}else if(item.type=='kongpan'){
                	// 控盘机器人
					return item.currency.split('_')[0]+this.$t('robot.budget')+dataArr[0]+', '+item.currency.split('_')[1]+this.$t('robot.budget')+dataArr[1]+', '+this.$t('robot.target')+dataArr[2]+', '+this.$t('robot.difference')+dataArr[3]+', '+this.$t('robot.paySmall')+dataArr[4]+', '+this.$t('robot.sellSmall')+dataArr[5]+', '+this.$t('robot.perDifference')+dataArr[6]+', '+this.$t('robot.maintenance')+dataArr[7]+', '+this.$t('robot.payMedium')+dataArr[8]+', '+this.$t('robot.sellMedium')+dataArr[9]+', '+this.$t('robot.perDifference')+dataArr[10]+', '+this.$t('robot.maintenance')+dataArr[11]+', '+this.$t('robot.payLarge')+dataArr[12]+', '+this.$t('robot.sellLarge')+dataArr[13]+', '+this.$t('robot.perDifference')+dataArr[14]+', '+this.$t('robot.maintenance')+dataArr[15]+', '+this.$t('robot.priceDecimal')+dataArr[16]
				}else if(item.type=='real_genpan_quick'){
                	// 主流币跟盘机器人
					return this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.difference')+dataArr[1]+'%, '+this.$t('robot.perDifference')+dataArr[2]+'%, '+this.$t('robot.buy')+dataArr[3]+', '+this.$t('robot.offers')+dataArr[4]+', '+this.$t('robot.priceDecimal')+dataArr[5]+', '+this.$t('robot.swiping')+dataArr[6]+this.$t('robot.individual')+', '+this.$t('robot.hop')+dataArr[7]
				}

				else if(item.type=='shua_dex'){
                	// Dex画线机器人
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.transaction')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.individual')+', '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.direction')+(dataArr[5]==0?this.$t('robot.oscillation'):dataArr[5]==1?this.$t('robot.rise'):dataArr[5]==2?this.$t('robot.fall'):dataArr[5])
				}else if(item.type=='real_genpan_dex'){
                	// Dex跟盘机器人
					return this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.targetPair')+dataArr[1]+'/'+dataArr[2]+', '+this.$t('robot.scaling')+dataArr[3]+'%, '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.transactionMax')+dataArr[4]+this.$t('robot.individual')
				}else if(item.type=='buy_sell_dex'){
                	// Dex高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+item.currency.split('_')[0]+', '+this.$t('robot.maximum')+dataArr[4]+item.currency.split('_')[0]+', '+this.$t('robot.minimum')+dataArr[5]+item.currency.split('_')[0]
				}else if(item.type=='frontrun_dex'){
                	// Dex抢买机器人
					return this.$t('robot.trigger')+dataArr[0]+item.currency.split('_')[1]+', '+this.$t('robot.everyPurchase')+dataArr[1]+item.currency.split('_')[1]+(dataArr[2]==1? ', '+this.$t('robot.isAutoSell'):'')
				}else if(item.type=='snipe_dex'){
                	// Dex狙击新盘
					// return this.$t('robot.purchase')+dataArr[0]+item.currency.split('_')[1]+(dataArr[1]? ', '+this.$t('robot.isAutoSell'):'')
					return this.$t('robot.contract')+dataArr[0]+', '+
					this.$t('robot.orderAmount')+dataArr[1]+item.currency.split('_')[1]+', '+
					this.$t('robot.multiple')+dataArr[2]+this.$t('robot.times')+', '+
					this.$t('robot.openingTime')+dataArr[3]+', '+
					(dataArr[4]==1? this.$t('robot.taxDeduction'):this.$t('robot.noTaxDeduction'))
				}else if(item.type=='banzhuan_dex'){
                	// DEX到CEX搬砖
					return this.$t('robot.targetExchange')+'(CEX)'+dataArr[0]+', '+this.$t('robot.currentCharge')+dataArr[1]+'%, '+this.$t('robot.targetCharge')+dataArr[2]+'%, '+this.$t('robot.priceDecimal')+dataArr[3]+', '+this.$t('robot.quantityDecimal')+dataArr[4]
				}
				else if(item.type=='shua_nft'){
                	// NFT画线机器人
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.transaction')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.individual')+', '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.direction')+(dataArr[5]==0?this.$t('robot.oscillation'):dataArr[5]==1?this.$t('robot.rise'):dataArr[5]==2?this.$t('robot.fall'):dataArr[5])
				}else if(item.type=='buy_sell_nft'){
                	// NFT高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+item.currency.split('_')[0]+', '+this.$t('robot.maximum')+dataArr[4]+item.currency.split('_')[0]+', '+this.$t('robot.minimum')+dataArr[5]+item.currency.split('_')[0]
				}
			},
			// 打开提示弹窗
			openTip(index){
				this.typeIndex=index;
				this.showTips = true;
			},
			// 编辑
			handleEdit(item,listIndex,index) {
				// console.log('item',item)
				// 选中项
				this.activeIndex = listIndex+'-'+index;
				// 机器人对应项
				this.typeIndex=item.typeIndex;
				this.selectItmeState=item.state;

				var currency=item.currency.split('_')

				this.web=item.web;
				this.exchange=item.web;
				this.yenUnit1=currency[0];
				this.yenUnit2=currency[1];
				this.param=item.param;
				// 弹窗
				this.showEdit=true;
				// 重新获取参数-传递param给组件 getRobot
				this.$nextTick(()=>{
					if(this.$refs.robotForm){
						if(
							(this.titleIndex==10&&item.sub_type=='asks')||
							(this.titleIndex==12&&item.sub_type=='rebalance')
						){
							// 传入默认单选项
							this.$refs.robotForm.paramInit(this.param,2);
						}else{
							this.$refs.robotForm.paramInit(this.param);
						}
					}
				});
			},
			beforeClose(done){
				this.activeIndex = null;
				done()
			},
			// 弹窗
			handleCancel() {
				this.showEdit = false;
			},
			handleConfirm(data,approve_type) {
				var param=data[0];
				var addSelectNum=data[1];
				var isRun=data[2];
				// console.log('param',param,addSelectNum)

				var index=this.typeIndex;
				var {type,sub_type}=typeFormat(index,addSelectNum)
				
				var currency;
				if(this.web!='opensea'){
					currency=this.yenUnit1+'_'+this.yenUnit2
				}else{
					currency=this.yenUnit1
				}
				// 如果保存并启动，则判断开启的机器人是否在关闭倒计时机器人中
				if(!!isRun&&this.closingRobotList.indexOf(this.typeIndex)>=0){
					// 正在关闭中，请稍后..
					this.$message({
						message: this.$t('tip.closingErr'),
						center: true,
						type: 'error',
						customClass: 'shotMsg',
					});
				}else{
					setRobot({
						web:this.web,
						currency,
						account:this.account,
						type:type,
						sub_type:sub_type,
						param:param,
						state:!!isRun?1:this.selectItmeState,
						approve_type:approve_type=='again'?'0':'1'
					}).then(res=>{
						if(res.code==2107||(res.code==2109&&res.type=='now')){
							// 未授权
							// 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web:this.web,
								account:this.account,
								approve_token:res.data.replace(',','_'),//代币
								is_master_approve:2,
							}).then(re=>{
								if(re.code==2172&&re.type=='confirm'){
									// 分拨授权
									this.address_list=re.data.address_list
									this.id_list=re.data.id_list
									this.chain=re.data.chain
									this.token=re.data.gas_token
									this.approve_token=re.data.approve_token
									this.transferWeb=this.web
									this.transferExchange=this.exchange
									this.$refs.subTransfer.open()
								}else if(re.code==2172&&re.type=='ignore'){
									// 忽略，重新操作
									this.handleConfirm(data,'again')
								}else{
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton:false,
									})
								}
							})
						}else if(res.code==2109&&res.type=='ignore'){
							// 忽略，重新操作
							this.handleConfirm(data,'again')
						}else if(res.code==2109&&res.type=='confirm'){
							// 分拨授权
							this.address_list=res.data.address_list
							this.id_list=res.data.id_list
							this.chain=res.data.chain
							this.token=res.data.gas_token
							this.approve_token=res.data.approve_token
							this.transferWeb=this.web
							this.transferExchange=this.exchange
							this.$refs.subTransfer.open()
						}else{
							this.getList()
							// 保存--成功
							this.$message({
								message: this.$t('tip.saveSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							this.showEdit = false;
							this.activeIndex = null;
						}
					})
				}
			},
			handleStart(item,listIndex,index,approve_type) {
				// console.log(item)
				this.activeIndex = listIndex+'-'+index;
				this.$confirm(this.$t('tip.startRobot'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					var data=Object.assign({},item)
					data.state=1;
					data.approve_type=approve_type=='again'?'0':'1'
					if(this.closingRobotList.indexOf(item.typeIndex)>=0){
						// 正在关闭中，请稍后..
						this.$message({
							message: this.$t('tip.closingErr'),
							center: true,
							type: 'error',
							customClass: 'shotMsg',
						});
					}else{
						setRobot(data).then(res=>{
							if(res.code==2107||(res.code==2109&&res.type=='now')){
								// 未授权
								// 提示授权中。。
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// 授权代币请求
								dexWalletsApprove({
									web:data.web,
									account:this.account,
									approve_token:res.data.replace(',','_'),//代币
									is_master_approve:2,
								}).then(re=>{
									if(re.code==2172&&re.type=='confirm'){
										// 分拨授权
										this.address_list=re.data.address_list
										this.id_list=re.data.id_list
										this.chain=re.data.chain
										this.token=re.data.gas_token
										this.approve_token=re.data.approve_token
										this.transferWeb=this.web
										this.transferExchange=this.exchange
										this.$refs.subTransfer.open()
									}else if(re.code==2172&&re.type=='ignore'){
										// 忽略，重新操作
										this.handleStart(item,listIndex,index,'again')
									}else{
										// 授权成功
										this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
											confirmButtonText: this.$t('common.confirm'),//'继续操作',
											// showConfirmButton:false,
											center: true,
											showClose: false,
											customClass: 'deleteOk',
											// cancelButtonText: this.$t('common.back'),
											showCancelButton:false,
										})
									}
								})
							}else if(res.code==2109&&res.type=='ignore'){
								// 忽略，重新操作
								this.handleStart(item,listIndex,index,'again')
							}else if(res.code==2109&&res.type=='confirm'){
								// 分拨授权
								this.address_list=res.data.address_list
								this.id_list=res.data.id_list
								this.chain=res.data.chain
								this.token=res.data.gas_token
								this.approve_token=res.data.approve_token
								this.transferWeb=this.web
								this.transferExchange=this.exchange
								this.$refs.subTransfer.open()
							}else{
								this.getList()
								// 启动--成功
								this.$message({
									message: this.$t('tip.startSuc'),
									center: true,
									type: 'success',
									customClass: 'shotMsg',
								});
							}
						})
					}
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			handleStop(item,listIndex,index,approve_type) {
				this.activeIndex = listIndex+'-'+index;
				this.$confirm(this.$t('tip.stopRobot'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					var data=Object.assign({},item)
					data.state=0;
					data.approve_type=approve_type=='again'?'0':'1'
					
					setRobot(data).then(res=>{
						if(res.code==2107||(res.code==2109&&res.type=='now')){
							// 未授权
							// 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web:data.web,
								account:this.account,
								approve_token:res.data.replace(',','_'),//代币
								is_master_approve:2,
							}).then(re=>{
								if(re.code==2172&&re.type=='confirm'){
									// 分拨授权
									this.address_list=re.data.address_list
									this.id_list=re.data.id_list
									this.chain=re.data.chain
									this.token=re.data.gas_token
									this.approve_token=re.data.approve_token
									this.transferWeb=this.web
									this.transferExchange=this.exchange
									this.$refs.subTransfer.open()
								}else if(re.code==2172&&re.type=='ignore'){
									// 忽略，重新操作
									this.handleStop(item,listIndex,index,'again')
								}else{
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton:false,
									})
								}
							})
						}else if(res.code==2109&&res.type=='ignore'){
							// 忽略，重新操作
							this.handleStop(item,listIndex,index,'again')
						}else if(res.code==2109&&res.type=='confirm'){
							// 分拨授权
							this.address_list=res.data.address_list
							this.id_list=res.data.id_list
							this.chain=res.data.chain
							this.token=res.data.gas_token
							this.approve_token=res.data.approve_token
							this.transferWeb=this.web
							this.transferExchange=this.exchange
							this.$refs.subTransfer.open()
						}else{
							this.getList()
							// 停止--成功
							this.$message({
								message: this.$t('tip.stopSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							// 如果停止成功 则提示10S倒计时自动关闭，并加入closingRobot
							this.closingRobotList.push(item.typeIndex)
							this.$set(this.closingRobot,item.typeIndex,10)
							var timeR=setInterval(()=>{
								var typeIndex=item.typeIndex
								if(this.closingRobot[typeIndex]>0){
									var time=this.closingRobot[typeIndex]-1
									this.$set(this.closingRobot,typeIndex,time)
								}else{
									this.closingRobotList.splice(this.closingRobotList.indexOf(time),1)
									clearInterval(timeR)
								}
							},1000)
						}
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			handleDelete(item,listIndex,index) {
				this.activeIndex = listIndex+'-'+index;
				this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					delRobot({
						web:item.web,
						currency:item.currency,
						account:item.account,
						robot_id:item.id
					}).then(res=>{
						this.getList()

						// 删除--成功
						this.$message({
							message: this.$t('tip.delSuc'),
							center: true,
							type: 'success',
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			
			// 交易所
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if(this.isSonPop){
					this.isSonPop=false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}
			},
			handleSelect(data) {
				if(this.isSonPop){
					this.$refs.robotForm.handleSelect(data,this.sonFormNum)
					// 子组件判定初始化
					this.isSonPop=false;
					this.popExchange = false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}else{
					this.exchange = data.text;
					this.web=data.web;
					this.popExchange = false;
				}
			},
			// 子组件交易所
			openPop(data,num){
				// 暂存原交易所数据
				this.web2 = this.web;
				// 子组件交易所数据展示
				this.web=data.web;

				this.isSonPop=true;
				this.popExchange = true;
				if(num){
					this.sonFormNum=num;
				}
			},
		}
	})
</script>
